/**
 * @flow
 */

import * as React from "react"
import cn from "classnames"
import _ from "lodash"
import { t } from "helpers/i18n"
import Avatar from "components/Avatar"
import AutoHoverPopover from "components/Popover/AutoHoverPopover"
import * as Routes from "helpers/routes"
import * as Navigation from "helpers/navigation"
import Map from "../Map"
import styles from "./styles.module.scss"

export type LoginAction = "LOGIN" | "LOGOUT" | "v2_start" | "v2_finish" | "v2_break_start" | "v2_break_finish"

type PhotoObject = {|
  action: LoginAction,
  geofence_status: string,
  is_mobile_clockin: boolean,
  latitude: ?number,
  longitude: ?number,
  map_url: ?string,
  url: string,
|}

type Props = {|
  photos: Array<PhotoObject>,
  userId: ?number,
|}

type State = {
  shouldRenderMap: boolean,
}

/**
 * takes an avatar (or two) and shows them. can also
 * link to a login feed.
 */

export default class LoginAvatar extends React.PureComponent<Props, State> {
  static defaultProps: {| userId: null |} = { userId: null }

  constructor(props: Props) {
    super(props)
    this.state = { shouldRenderMap: false }
  }

  hasAvatar: () => boolean = () => !!this.frontPhoto()

  handleClick: () => void = () => {
    if (this.hasAvatar() && this.props.userId) {
      Navigation.goto(Routes.logins_user_path(this.props.userId))
    }
  }

  handleHover: () => void = () => {
    this.setState({ shouldRenderMap: true })
  }

  frontPhoto: () => string = () => (this.props.photos[0] || {}).url

  frontPhotoGeofenceStatus: () => string = () => {
    // front icon is red if there is any red icon in shift
    // front icon is blue if there are no red icons and any blue icons
    // front icon is green only if all icons in shift are green
    if (!_.some(this.props.photos)) {
      // no photos -> don't display icon
      return "unavailable"
    }
    for (const photo of this.props.photos) {
      if (
        ["offsite", "missing_login_coords", "missing_location_coords"].includes(photo.geofence_status) &&
        photo.is_mobile_clockin
      ) {
        return photo.geofence_status
      }
    }
    if (
      this.props.photos.filter((photo) => photo.is_mobile_clockin).every((photo) => photo.geofence_status === "onsite")
    ) {
      return "onsite"
    }

    return "unavailable"
  }

  frontPhotoMobileClockIn: () => boolean = () => this.props.photos.some((photo) => photo.is_mobile_clockin)

  renderMap: (photo: PhotoObject) => ?React.Element<"div"> = (photo: PhotoObject) => {
    // don't render the map until we hover over it
    // we do this to prevent repeated API calls
    // once hovered, we sould always render so we don't re-do the API call
    // every time we re-render
    if (!this.state.shouldRenderMap) {
      return null
    }

    if (photo.is_mobile_clockin && photo.latitude && photo.longitude && photo.map_url) {
      return (
        <div className={styles.map}>
          <Map latitude={photo.latitude} longitude={photo.longitude} mapUrl={photo.map_url} />
        </div>
      )
    }
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(styles.loginAvatar, { [styles.showPointer]: this.hasAvatar() })}
        onClick={this.handleClick}
        onKeyDown={_.noop}
        onMouseEnter={this.handleHover}
        role="presentation"
      >
        <Avatar
          alt={t("js.timesheets.shift_card.avatar.alt")}
          geofenceStatus={this.frontPhotoGeofenceStatus()}
          mobileClockIn={this.frontPhotoMobileClockIn()}
          size="m"
          source={this.frontPhoto()}
        />

        {this.props.photos.length > 0 && (
          <AutoHoverPopover position="right" title={t("js.timesheets.shift_card.avatar.popover_title")} width="large">
            <div className={styles.popoverContent}>
              <div
                className={styles.avatarContainer}
                style={{ flexDirection: this.props.photos.some((photo) => photo.is_mobile_clockin) ? "column" : "row" }}
              >
                {this.props.photos.map((photo) => (
                  <div className={styles.avatarInnerContainer} key={photo.url}>
                    <Avatar
                      alt={t("js.timesheets.shift_card.avatar.alt")}
                      bordered
                      geofenceStatus={photo.geofence_status}
                      mobileClockIn={photo.is_mobile_clockin}
                      source={photo.url}
                    />
                    {this.renderMap(photo)}
                  </div>
                ))}
              </div>
            </div>
          </AutoHoverPopover>
        )}
      </div>
    )
  }
}
