/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import _ from "lodash"
import * as Airbrake from "airbrake"
import * as LeaveHelpers from "helpers/leave_helpers"
import Request from "helpers/request"
import * as Routes from "helpers/routes"
import * as TimeHelpers from "helpers/time"
import { t as globalT } from "helpers/i18n"
import Button from "components/Button"
import ModalView, { Header, Footer, FooterLeft, FooterRight } from "components/Modal"
import SafeTransition from "timesheets/components/SafeTransition"
import Failure from "time_off/components/Failure"
import Form from "time_off/components/Form/LeaveRequestForm"
import Loading from "time_off/components/Loading"
import { hasBeenModifiedFrom } from "../edit_daily_breakdown/helpers/validators"
import { dirtifyFilledFrom, cleanFilledFrom } from "../edit_daily_breakdown/helpers/formatters"
import type {
  CurrentUser,
  Defaults,
  Department,
  LeaveInfo,
  Time,
  LeaveUser,
  DailyBreakdown,
  ShiftSummary,
  LeaveBalance,
  LeavesListItem,
  FillFromRosterStrategy,
  PptSchedule,
  ShiftsListItem,
} from "../types"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.${key}`, opts)

type Props = {|
  +defaults: Defaults,
  +onCancel: () => void,
  +onSuccess: (leaveInfo: LeaveInfo) => void,
  +open: boolean,
|}

type State = {|
  allDay: boolean,
  averageWorkDayLength: number,
  currentUser: CurrentUser,
  daily_breakdown: DailyBreakdown,
  days: ?string,
  defaultLeaveLength: number,
  departmentId: ?string,
  departments: Array<Department>,
  endDate: moment,
  endTime: Time,
  fallbackLeaveTypeName: ?string,
  fetchingDailyBreakdown: boolean,
  fetchingLeaveAppliesOnDates: boolean,
  fetchingLeavesList: boolean,
  fetchingShiftsList: boolean,
  fillFromRosterStrategy: FillFromRosterStrategy,
  formHeight: string,
  futureLeavesList: Array<{ ...LeavesListItem, hours: number }>,
  hideHours: boolean,
  hours: string,
  init: "complete" | "failure" | "in-progress",
  initialDailyBreakdown: DailyBreakdown,
  isLeaveAveragingLeaveType: boolean,
  leaveAppliesOnDates: { [string]: boolean },
  leavesList: Array<LeavesListItem>,
  leaveTypeName: ?string,
  manualHours: boolean,
  pendingPptAcceptanceDates: { [date: string]: Array<PptSchedule> },
  reason: string,
  selfApprove: boolean,
  shiftOverlap: boolean,
  shiftsList: Array<ShiftsListItem>,
  startDate: moment,
  startTime: Time,
  submit: "failure" | "in-progress" | "ready",
  submitError: ?string,
  userId: ?string,
  users: Array<LeaveUser>,
  verification: ?File,
  verificationTypes: Array<string>,
  viewInDays: boolean,
|}

const MAX_FILE_SIZE = 5000000

export default class Modal extends React.PureComponent<Props, State> {
  form: ?HTMLDivElement

  static defaultProps: {|
    defaults: {|
      departmentId: null,
      endDate: null,
      endTime: null,
      leaveLength: null,
      startDate: null,
      startTime: null,
      userId: null,
    |},
  |} = {
    defaults: {
      departmentId: null,
      endDate: null,
      endTime: null,
      leaveLength: null,
      startDate: null,
      startTime: null,
      userId: null,
    },
  }

  static dateClash: (endDate: moment$Moment, startDate: moment$Moment) => boolean = (
    endDate: moment,
    startDate: moment
  ) => startDate.isAfter(endDate)

  static timeClash: (endDate: moment$Moment, endTime: Time, startDate: moment$Moment, startTime: Time) => boolean = (
    endDate: moment,
    endTime: Time,
    startDate: moment,
    startTime: Time
  ) =>
    moment(startDate)
      .set({ h: startTime.hour % 24, m: startTime.minute })
      .isAfter(moment(endDate).set({ h: endTime.hour % 24, m: endTime.minute }))

  // Makes sure overnight leave reqs don't go over 24 hours long
  static isOvernightAndTooLong: (
    endDate: moment$Moment,
    endTime: Time,
    startDate: moment$Moment,
    startTime: Time
  ) => boolean = (endDate, endTime, startDate, startTime) =>
    !TimeHelpers.isGreaterThanTwoDays(startDate, endDate) &&
    moment(endDate).startOf("day").isAfter(startDate) &&
    (endTime.hour > startTime.hour || (endTime.hour === startTime.hour && endTime.minute >= startTime.minute))

  static isUserLoaded: (userId?: ?string) => boolean = (userId?: ?string) =>
    userId !== null && userId !== undefined && userId.length > 0

  static timeStringForAPICall: (time: Time) => string = (time: Time) =>
    moment({ ...time, s: 0 }).format(TimeHelpers.Formats.DateTime)

  static freshState: (
    defaults: Defaults,
    currState: ?State
  ) => {|
    allDay: boolean,
    averageWorkDayLength: number,
    currentUser: CurrentUser,
    daily_breakdown: DailyBreakdown,
    days: ?string,
    defaultLeaveLength: number,
    departmentId: ?string,
    departments: Array<Department>,
    endDate: moment$Moment,
    endTime: Time,
    fallbackLeaveTypeName: ?string,
    fetchingDailyBreakdown: boolean,
    fetchingLeaveAppliesOnDates: boolean,
    fetchingLeavesList: boolean,
    fetchingShiftsList: boolean,
    fillFromRosterStrategy: FillFromRosterStrategy,
    formHeight: string,
    futureLeavesList: Array<{ ...LeavesListItem, hours: number, ... }>,
    hideHours: boolean,
    hours: string,
    init: "complete" | "failure" | "in-progress",
    initialDailyBreakdown: DailyBreakdown,
    isLeaveAveragingLeaveType: boolean,
    leaveAppliesOnDates: { [string]: boolean },
    leavesList: Array<LeavesListItem>,
    leaveTypeName: ?string,
    manualHours: boolean,
    pendingPptAcceptanceDates: { [date: string]: Array<PptSchedule> },
    reason: string,
    selfApprove: boolean,
    shiftOverlap: boolean,
    shiftsList: Array<ShiftsListItem>,
    startDate: moment$Moment,
    startTime: Time,
    submit: "failure" | "in-progress" | "ready",
    submitError: ?string,
    userId: ?string,
    users: Array<LeaveUser>,
    verification: ?File,
    verificationTypes: Array<string>,
    viewInDays: boolean,
  |} = (defaults: Defaults, currState: ?State) => ({
    allDay: !(defaults.startTime && defaults.endTime),
    averageWorkDayLength: 0,
    currentUser: currState
      ? currState.currentUser
      : { id: "-1", timeFormat: 12, isManager: false, canApproveOwnLeaveRequest: false, country: "Australia" },
    daily_breakdown: [],
    days: "",
    defaultLeaveLength: 0,
    departmentId: defaults.departmentId || null,
    departments: currState ? currState.departments : [],
    endDate: defaults.endDate || moment(),
    endTime: defaults.endTime || { hour: 17, minute: 0 },
    fallbackLeaveTypeName: null,
    fetchingDailyBreakdown: false,
    fetchingLeaveAppliesOnDates: false,
    fetchingLeavesList: false,
    fetchingShiftsList: false,
    fillFromRosterStrategy: "keep",
    formHeight: "auto",
    hideHours: true,
    hours: defaults.leaveLength || "",
    init: currState ? currState.init : "in-progress",
    initialDailyBreakdown: [],
    isLeaveAveragingLeaveType: false,
    leaveAppliesOnDates: {},
    leavesList: [],
    futureLeavesList: [],
    leaveTypeName: null,
    manualHours: false,
    pendingPptAcceptanceDates: {},
    reason: "",
    selfApprove: currState ? currState.selfApprove : true,
    shiftsList: [],
    shiftOverlap: false,
    startDate: defaults.startDate || moment(),
    startTime: defaults.startTime || { hour: 9, minute: 0 },
    submit: "ready",
    submitError: null,
    userId: defaults.userId || (currState ? currState.userId : null),
    users: currState ? currState.users : [],
    verification: null,
    verificationTypes: currState ? currState.verificationTypes : [],
    viewInDays: false,
  })

  state: State = Modal.freshState(this.props.defaults)

  componentDidMount() {
    window.addEventListener("resize", this.onHeightAdjust)
    this.downloadState()
  }

  UNSAFE_componentWillReceiveProps({ defaults }: Props) {
    if (defaults !== this.props.defaults) {
      this.setState(Modal.freshState(defaults, this.state), () => {
        if (defaults.userId != null) {
          // Get balances if we only have one user since it will automatically be selected.
          this.getUserState()
        }
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onHeightAdjust)
  }

  // eslint-disable-next-line flowtype/no-weak-types
  downloadState: () => any = () =>
    Request.get(Routes.modal_initial_state_leave_index_path())
      .then(
        (res: {
          data: {|
            +currentUser: CurrentUser,
            +users: Array<LeaveUser>,
            +verificationTypes: Array<string>,
          |},
        }) => {
          this.setState(
            {
              currentUser: res.data.currentUser,
              init: "complete",
              userId: res.data.users.length === 1 ? res.data.users[0].id : null,
              users: res.data.users,
              verificationTypes: res.data.verificationTypes,
            },
            () => {
              if (res.data.users.length === 1) {
                // Get balances if we only have one user since it will automatically be selected.
                this.getUserState()
              }
            }
          )
        }
      )
      .catch(() => {
        this.setState({ init: "failure" })
      })

  handleAllDay: (allDay: boolean) => void = (allDay: boolean) => {
    let exampleOvernightTimes = {}
    if (
      !allDay &&
      TimeHelpers.isDifferentDay(this.state.startDate, this.state.endDate) &&
      _.isEqual(this.state.startTime, this.state.endTime)
    ) {
      // If the user is changing all_day to request overnight leave, and the start and end times are the same
      // (likely due to validations preventing end time from being after start time on multiday timed requests),
      // set the times to an example _overnight_ span, to clearly indicate that the user is requesting overnight leave.
      // By doing this we're assuming a user doesn't want to request 24 straight hours of leave.
      exampleOvernightTimes = {
        startTime: { hour: 18, minute: 0 },
        endTime: { hour: 2, minute: 0 },
      }
    }
    this.setState({ allDay, ...exampleOvernightTimes }, () => {
      if (Modal.isUserLoaded(this.state.userId)) {
        this.getDailyBreakdown()
      }
    })
  }

  handleShiftOverlapChange: (shiftOverlap: boolean) => void = (shiftOverlap: boolean) => {
    this.setState({ shiftOverlap: shiftOverlap })
  }

  handleDepartmentIdChange: (departmentId: string) => void = (departmentId: string) => {
    this.setState({
      departmentId,
      daily_breakdown: this.state.daily_breakdown.map((shiftSummary) => ({
        ...shiftSummary,
        department_id: departmentId,
      })),
    })
  }

  handleEndDateChange: (endDate: moment$Moment) => void = (endDate: moment) => {
    const newStartDate = Modal.dateClash(endDate, this.state.startDate) ? endDate : this.state.startDate
    this.setState(
      {
        endDate,
        startDate: newStartDate,
        allDay: this.state.allDay || TimeHelpers.isDifferentDay(newStartDate, endDate),
      },
      () => {
        this.handleEndTimeChange(this.state.endTime)
      }
    )
  }

  handleEndTimeChange: (endTime: Time) => void = (endTime: Time) => {
    const { endDate, startDate, startTime } = this.state

    this.setState(
      {
        endTime: Modal.isOvernightAndTooLong(endDate, endTime, startDate, startTime) ? { ...startTime } : endTime,
        startTime: Modal.timeClash(endDate, endTime, startDate, startTime) ? { ...endTime } : startTime,
      },
      () => {
        if (Modal.isUserLoaded(this.state.userId)) {
          this.getDailyBreakdown()
          this.getLeavesList()
          this.getShiftsList()
          this.getLeaveAppliesOnDates()
        }
      }
    )
  }

  handleHoursChange: (hours: string) => void = (hours: string) => {
    const newHours = hours.length > 0 ? hours : "0"
    if (this.state.daily_breakdown.length === 0) {
      return
    }

    // Unless hours is set to 0, filter out the days with 0 hours, and spread the new hours over the remaining days.
    const spreadOverNoHourShifts = this.state.initialDailyBreakdown.every((ss) => ss.hours === 0)
    const numberOfValidShifts = this.state.daily_breakdown.filter(
      (shiftSummary, i) =>
        spreadOverNoHourShifts ||
        this.state.initialDailyBreakdown[i].hours !== 0 ||
        (shiftSummary.hours != null && shiftSummary.hours !== 0)
    ).length
    const newDailyBreakdown = this.state.daily_breakdown.map((shiftSummary, i) => {
      const initialSummary = this.state.initialDailyBreakdown[i]
      if (
        spreadOverNoHourShifts ||
        initialSummary.hours !== 0 ||
        (shiftSummary.hours != null && shiftSummary.hours !== 0)
      ) {
        const hoursForSummary =
          parseFloat(newHours) /
          numberOfValidShifts /
          // If multiple shifts on one day, split the hours for that day between those shifts
          this.state.daily_breakdown.filter((summary) => summary.date === shiftSummary.date).length
        return {
          ...shiftSummary,
          // Spread hours evenly over each day
          hours: hoursForSummary,
          filled_from: hasBeenModifiedFrom({ ...shiftSummary, hours: hoursForSummary }, initialSummary)
            ? cleanFilledFrom(shiftSummary.filled_from)
            : dirtifyFilledFrom(shiftSummary.filled_from),
        }
      } else {
        return shiftSummary
      }
    })

    this.setState({
      hours,
      manualHours: true,
      daily_breakdown: newDailyBreakdown,
    })
  }

  handleLeaveTypeChange: (leaveTypeName: string) => void = (leaveTypeName: string) => {
    const leaveType = (
      (this.state.users.find((u) => u.id === this.state.userId) || {}).leaveBalances.find(
        (lb) => lb.leaveType.name === leaveTypeName
      ) || {}
    ).leaveType
    this.setState(
      {
        averageWorkDayLength: leaveType.averageWorkDayLength,
        defaultLeaveLength: leaveType.defaultLeaveLength,
        leaveTypeName,
        fallbackLeaveTypeName: leaveType.defaultFallbackLeaveTypeName,
        viewInDays: leaveType.viewInDays,
        isLeaveAveragingLeaveType: leaveType.isLeaveAveragingLeaveType,
      },
      () => {
        this.getDailyBreakdown()
        this.getBalanceProjections()
        this.getLeavesList()
        this.getLeaveAppliesOnDates()
        this.getAllFutureLeaveList()
      }
    )
  }

  handleFallbackLeaveTypeChange: (fallbackLeaveTypeName: string) => void = (fallbackLeaveTypeName: string) => {
    this.setState({ fallbackLeaveTypeName })
  }

  handleReasonChange: (reason: string) => void = (reason: string) => {
    this.setState({ reason })
  }

  handleStartDateChange: (startDate: moment$Moment) => void = (startDate: moment) => {
    const newEndDate = Modal.dateClash(this.state.endDate, startDate) ? startDate : this.state.endDate
    this.setState(
      {
        endDate: newEndDate,
        startDate,
        allDay: this.state.allDay || TimeHelpers.isDifferentDay(startDate, newEndDate),
      },
      () => {
        this.handleStartTimeChange(this.state.startTime)
      }
    )
  }

  handleStartTimeChange: (startTime: Time) => void = (startTime: Time) => {
    const { endDate, endTime, startDate } = this.state

    this.setState(
      {
        endTime: Modal.timeClash(endDate, endTime, startDate, startTime) ? { ...startTime } : endTime,
        startTime: Modal.isOvernightAndTooLong(endDate, endTime, startDate, startTime) ? { ...endTime } : startTime,
      },
      () => {
        if (Modal.isUserLoaded(this.state.userId)) {
          this.getDailyBreakdown()
          this.getBalanceProjections()
          this.getLeavesList()
          this.getShiftsList()
          this.getLeaveAppliesOnDates()
        }
      }
    )
  }

  handleUserIdChange: (userId: string) => void = (userId: string) => {
    this.setState({ userId }, () => {
      this.getUserState()
      this.getShiftsList()
    })
  }

  handleVerificationChange: (verification: ?File) => void = (verification: ?File) => {
    this.setState({ verification })
  }

  handleFillFromRosterStrategyChange: (fillFromRosterStrategy: FillFromRosterStrategy) => void = (
    fillFromRosterStrategy: FillFromRosterStrategy
  ) => {
    this.setState({ fillFromRosterStrategy })
  }

  resetSubmission: () => void = () => this.setState({ submit: "ready" })

  invalidFile: () => boolean = () =>
    this.state.verification != null &&
    (this.state.verification.size > MAX_FILE_SIZE ||
      !this.state.verificationTypes.includes(this.state.verification.type))

  getCurrentLeaveBalance: (leaveTypeName: ?string) => ?LeaveBalance = (leaveTypeName: ?string): ?LeaveBalance => {
    const user = this.state.users.find((u) => u.id === this.state.userId)
    if (user == null) {
      return null
    }

    return user.leaveBalances?.find((lb) => lb.leaveType.name === leaveTypeName)
  }

  getCurrentLeaveBalanceHours: (leaveTypeName: ?string) => number = (leaveTypeName: ?string): number => {
    const lb = this.getCurrentLeaveBalance(leaveTypeName)

    if (lb?.predictions == null) {
      return 0
    }

    const dateAsString = this.state.startDate?.isValid() ? this.state.startDate.format("YYYY-MM-DD") : null

    if (dateAsString == null || (lb.leaveType.preventNegative && lb.leaveType.preventNegativeIgnorePredictions)) {
      return lb.hours || 0
    }

    return (lb.predictions?.[dateAsString] == null ? lb.hours : lb.predictions[dateAsString]) || 0
  }

  // Returns a number equal to the amount of leave balance (hours) that will be left
  // if this request is approved.
  // Returns 0 if the selected leave type allows negative balances, since we don't
  // care about what that number is.
  getFinalLeaveBalance: () => number = (): number => {
    const { startDate, leaveTypeName, userId, hours } = this.state

    if (leaveTypeName == null) {
      return 0
    }
    if (startDate == null) {
      return 0
    }
    if (userId == null) {
      return 0
    }
    if (hours == null) {
      return 0
    }
    const numHours = parseFloat(hours)

    const currentLB = this.getCurrentLeaveBalance(leaveTypeName)
    if (currentLB == null || currentLB.hours == null) {
      return 0
    }
    if (!currentLB.leaveType.preventNegative) {
      return 0
    }
    // Round down to the nearest 1/100th of an hour
    return Math.floor((this.getCurrentLeaveBalanceHours(leaveTypeName) - numHours) * 100) / 100
  }

  // eslint-disable-next-line flowtype/no-weak-types
  getUserState: () => any | void = () => {
    const { userId, users } = this.state

    const user = users.find((u) => u.id === userId)

    // if these are not null we must have already loaded the data and
    // we do not need to proceed with the request.
    if (user?.departments != null && user?.leaveBalances != null) {
      return
    }

    return Request.get(
      Routes.modal_user_state_leave_index_path({
        user_id: userId,
      })
    ).then((res) => {
      const { departments, leaveBalances, userId } = res.data

      this.setState(
        {
          departmentId: departments.find((d) => d.id === user?.reportDepartment)?.id,
          users: this.state.users.map((u) => (u.id !== userId ? u : { ...u, departments, leaveBalances })),
        },
        () => {
          this.getBalances()
          this.getBalanceProjections()
          this.getLeavesList()
          this.getShiftsList()
        }
      )
    })
  }

  invalidForm: () => boolean = () => {
    let invalidLeaveBalance = false
    // We determine if the user's leave balance is valid for submission.
    // As a workaround the `prevent_negative` leave setting, we allow managers to create
    // leave requests for users they manage even if it would otherwise prevent it.
    if (this.getFinalLeaveBalance() < 0 && !this.state.fallbackLeaveTypeName) {
      if (!this.state.currentUser.isManager) {
        invalidLeaveBalance = true
      } else if (this.state.currentUser.id === this.state.userId && !this.state.currentUser.canApproveOwnLeaveRequest) {
        invalidLeaveBalance = true
      }
    }

    const { daily_breakdown } = this.state
    let invalidDailyBreakdown = false // don't validate if there is no daily breakdown present
    if (!_.isEmpty(daily_breakdown)) {
      invalidDailyBreakdown =
        parseFloat(this.getTotalHours(daily_breakdown)) <= 0 ||
        daily_breakdown.some((shiftSummary) => shiftSummary.hours > 24)
    }

    return (
      parseFloat(this.state.hours || 0) <= 0 ||
      this.state.userId == null ||
      this.state.leaveTypeName == null ||
      invalidLeaveBalance ||
      invalidDailyBreakdown
    )
  }

  isSyncing: () => boolean = () => this.state.submit === "in-progress"

  isWithOverlappingApprovedRequest: () => number | boolean = () => {
    const { leavesList, leaveTypeName, currentUser } = this.state
    if (currentUser.isManager) {
      return false
    } else {
      return leavesList.filter((leave) => leave.status === "approved" && leave.leaveType === leaveTypeName).length
    }
  }

  fileFormatError: () => string = () =>
    this.state.verification != null && !this.state.verificationTypes.includes(this.state.verification.type)
      ? t("file.error_format")
      : ""

  fileSizeError: () => string = () =>
    this.state.verification != null && this.state.verification.size > MAX_FILE_SIZE ? t("file.error_size") : ""

  getBalances: () => void = () => {
    const { userId } = this.state

    Request.get(Routes.get_displayable_balances_leave_index_path({ user_id: userId }), {
      // by default we raise unhandled 400s and send them to airbrake
      // but we don't care about that here. if a request is bad (eg. leave balance doesn't exist)
      // we just don't do anything.
      validateStatus: null,
    }).then((res) => {
      const newUsers = this.state.users.map((stateUser) => {
        if (stateUser.id !== userId) {
          return stateUser
        }

        return {
          ...stateUser,
          leaveBalances: stateUser.leaveBalances.map((lb) => {
            const relevant_balance = res.data.find((resBalance) => resBalance.leave_type === lb.leaveType.name)

            if (relevant_balance == null) {
              return lb
            }

            return {
              ...lb,
              hours: relevant_balance.hours,
            }
          }),
        }
      })

      this.setState({ users: newUsers })
    })
  }

  getBalanceProjections: () => void = () => {
    const { startDate, leaveTypeName, userId } = this.state

    if (leaveTypeName == null) {
      return
    }
    // Skip projections if leave start is before or on current day. No way there could be any accruals if this is true
    if (startDate == null || startDate.isSameOrBefore(moment(), "day")) {
      return
    }
    if (userId == null) {
      return
    }

    const user = this.state.users.find((u) => u.id === this.state.userId)
    if (user == null) {
      return
    }
    const valid_country =
      this.state.currentUser.country !== "Australia" && this.state.currentUser.country !== "New Zealand"

    const route =
      this.canCreateAndApprove() || valid_country
        ? Routes.get_current_balance_leave_index_path({
            user_id: userId,
            leave_type: leaveTypeName,
            as_at: startDate.format("YYYY-MM-DD"),
            include_predicted_accruals: true,
          })
        : Routes.get_available_balance_leave_index_path({
            user_id: userId,
            leave_type: leaveTypeName,
            as_at: startDate.format("YYYY-MM-DD"),
            include_predicted_accruals: true,
          })

    Request.get(route, {
      // by default we raise unhandled 400s and send them to airbrake
      // but we don't care about that here. if a request is bad (eg. leave balance doesn't exist)
      // we just don't do anything.
      validateStatus: null,
    }).then((res) => {
      const newUsers = this.state.users.map((stateUser) => {
        if (stateUser.id !== userId) {
          return stateUser
        }

        return {
          ...stateUser,
          leaveBalances: stateUser.leaveBalances.map((lb) => {
            if (lb.leaveType.name !== leaveTypeName) {
              return lb
            }

            return {
              ...lb,
              predictions: { ...lb.predictions, [startDate.format("YYYY-MM-DD")]: parseFloat(res.data.toString()) },
            }
          }),
        }
      })

      this.setState({ users: newUsers })
    })
  }

  getDailyBreakdown: () => void = () => {
    this.setState({
      fetchingDailyBreakdown: true,
      daily_breakdown: [],
      initialDailyBreakdown: [],
      pendingPptAcceptanceDates: {},
    })

    Request.get(
      Routes.get_daily_breakdown_additional_details_leave_index_path({
        all_day: this.state.allDay,
        finish: this.state.endDate.format("YYYY-MM-DD"),
        finish_time: this.state.allDay ? null : Modal.timeStringForAPICall(this.state.endTime),
        hours: this.state.startTime && this.state.endTime && !this.state.viewInDays ? this.state.hours : null,
        leave_type: this.state.leaveTypeName,
        start: this.state.startDate.format("YYYY-MM-DD"),
        start_time: this.state.allDay ? null : Modal.timeStringForAPICall(this.state.startTime),
        user_id: parseInt(this.state.userId, 10),
        view_in_days: this.state.viewInDays,
      })
    )
      .then((res) => {
        let { daily_breakdown } = res.data
        let new_dept_id = this.state.departmentId
        // If any of the breakdown dept ids are non-null, override current dept id (null for multi team)
        if (daily_breakdown.some((ss) => ss.department_id != null)) {
          const breakdown_teams = _.uniq(daily_breakdown.map((ss) => ss.department_id).filter(Boolean))
          new_dept_id = breakdown_teams.length === 1 ? breakdown_teams[0] : null
        } else {
          // If they're all null, override the breakdown teams.
          daily_breakdown = daily_breakdown.map((ss) => ({
            ...ss,
            department_id: this.state.departmentId,
          }))
        }
        const total_hours = this.getTotalHours(daily_breakdown)
        this.setState({
          daily_breakdown: daily_breakdown,
          days: this.state.viewInDays
            ? (parseFloat(this.getTotalHours(daily_breakdown)) / this.state.averageWorkDayLength).toString()
            : "",
          fetchingDailyBreakdown: false,
          hideHours: false,
          // If hours are 0, clear the field so the user has to manually input the hours.
          hours: total_hours !== "0" ? total_hours : "",
          initialDailyBreakdown: daily_breakdown,
          departmentId: new_dept_id,
          pendingPptAcceptanceDates: res.data.pending_ppt_acceptance_dates,
        })
      })
      .catch((error) => {
        Airbrake.notify(error)
      })
  }

  getAllFutureLeaveList: () => void = () => {
    this.setState({ futureLeavesList: [] })
    const { userId } = this.state
    const data = {
      // we don't care about the end date here
      start: moment().format("YYYY-MM-DD"),
      status: "all",
      format: "json",
      user_id: userId,
    }

    Request.get(Routes.request_list_leave_index_path(data))
      .then((response) => {
        const { requests } = response.data
        const nonRejectedRequests = requests.filter((request) => request.status !== "rejected")
        const requestsWithCurrentType = nonRejectedRequests.filter(
          (request) => request.leave_type === this.state.leaveTypeName
        )
        const futureLeavesList = requestsWithCurrentType.map((request) => ({
          finish: request.finish,
          id: request.id,
          leaveType: request.leave_type,
          reason: request.reason,
          start: request.start,
          status: request.status,
          hours: request.hours,
        }))
        this.setState({ futureLeavesList })
      })
      .catch((error) => {
        Airbrake.notify(error)
      })
  }

  getLeavesList: () => void = () => {
    this.setState({ fetchingLeavesList: true, leavesList: [] })
    const { startDate, endDate, userId } = this.state
    const data = {
      start: startDate.format("YYYY-MM-DD"),
      finish: endDate.format("YYYY-MM-DD"),
      status: "all",
      format: "json",
      user_id: userId,
    }

    Request.get(Routes.request_list_leave_index_path(data))
      .then((response) => {
        const { requests } = response.data
        const nonRejectedRequests = requests.filter((request) => request.status !== "rejected")
        const leavesList = nonRejectedRequests.map((request) => ({
          finish: request.finish,
          id: request.id,
          leaveType: request.leave_type,
          reason: request.reason,
          start: request.start,
          status: request.status,
        }))
        this.setState({ leavesList, fetchingLeavesList: false })
      })
      .catch((error) => {
        Airbrake.notify(error)
      })
  }

  getShiftsList: () => void = () => {
    this.setState({ fetchingShiftsList: true, shiftsList: [] })
    const { startDate, endDate, userId } = this.state
    const data = {
      timesheet_id: "timesheet_id",
      start: startDate.format("YYYY-MM-DD"),
      end: endDate.format("YYYY-MM-DD"),
      status: "all",
      format: "json",
      user_id: userId,
    }
    Request.get(Routes.shifts_within_date_range_path(data))
      .then((response) => {
        const { shifts } = response.data
        const shiftsList =
          shifts &&
          shifts.map((shift) => ({
            timesheet_id: shift.timesheet_id,
            end: shift.end,
            id: shift.id,
            start: shift.start,
            status: shift.status,
          }))
        this.setState({ shiftsList: shiftsList || [], fetchingShiftsList: false })
      })
      .catch((error) => {
        Airbrake.notify(error)
      })
  }

  getLeaveAppliesOnDates: () => void = () => {
    const { startDate, endDate, userId, leaveTypeName } = this.state

    if (startDate && endDate && userId && leaveTypeName) {
      this.setState({
        leaveAppliesOnDates: {},
        fetchingLeaveAppliesOnDates: true,
      })

      const queryParams = {
        date_from: startDate.format("YYYY-MM-DD"),
        date_to: endDate.format("YYYY-MM-DD"),
        user_id: userId,
        leave_type: leaveTypeName,
      }

      Request.get(Routes.leave_applies_on_dates_leave_index_path(queryParams))
        .then((res) =>
          this.setState({
            leaveAppliesOnDates: res.data,
            fetchingLeaveAppliesOnDates: false,
          })
        )
        .catch((error) => {
          Airbrake.notify(error)
          this.setState({
            leaveAppliesOnDates: {},
            fetchingLeaveAppliesOnDates: false,
          })
        })
    }
  }

  handleSaveDailyBreakdown: (daily_breakdown: DailyBreakdown) => void = (daily_breakdown: DailyBreakdown) => {
    const chronological_breakdown = [...daily_breakdown]
    chronological_breakdown.sort((a, b) => {
      if (a.date < b.date) {
        return -1
      } else if (a.date > b.date) {
        return 1
      } else if (a.start_time < b.start_time) {
        return -1
      } else if (a.start_time > b.start_time) {
        return 1
      } else {
        return 0
      }
    })
    const newAllDay = !(
      daily_breakdown.map((ss) => ss.all_day).every((ad) => ad === false) && daily_breakdown.length === 1
    )
    const newStartTime = TimeHelpers.fromString(chronological_breakdown[0].start_time)
    // Re-sort for finish time
    chronological_breakdown.sort((a, b) => {
      if (a.date < b.date) {
        return -1
      } else if (a.date > b.date) {
        return 1
      } else if (a.finish_time < b.finish_time) {
        return -1
      } else if (a.finish_time > b.finish_time) {
        return 1
      } else {
        return 0
      }
    })
    const newEndTime = TimeHelpers.fromString(chronological_breakdown[chronological_breakdown.length - 1].finish_time)

    let possibleNewTimes: {
      endTime: Time,
      startTime: Time,
    } = {}
    if (!newAllDay && newStartTime.hour != null && newStartTime.minute != null) {
      possibleNewTimes = {
        ...possibleNewTimes,
        startTime: {
          hour: newStartTime.hour,
          minute: newStartTime.minute,
        },
      }
    }
    if (!newAllDay && newEndTime.hour != null && newEndTime.minute != null) {
      possibleNewTimes = {
        ...possibleNewTimes,
        endTime: {
          hour: newEndTime.hour,
          minute: newEndTime.minute,
        },
      }
    }
    this.setState({
      ...possibleNewTimes,
      daily_breakdown,
      days: this.state.viewInDays
        ? (parseFloat(this.getTotalHours(daily_breakdown)) / this.state.defaultLeaveLength).toString()
        : "",
      hours: this.getTotalHours(daily_breakdown),
      allDay: newAllDay,
    })
  }

  handleSelfApproveChange: (selfApprove: boolean) => void = (selfApprove) => {
    if (this.canCreateAndApprove()) {
      this.setState({ selfApprove })
    }
  }

  getTotalHours: (daily_breakdown: DailyBreakdown) => string = (daily_breakdown: DailyBreakdown): string => {
    const hoursArray = daily_breakdown.map((summary: ShiftSummary) => (summary.hours != null ? summary.hours : 0))

    return parseFloat(hoursArray.reduce((hours, currVal) => parseFloat(hours) + parseFloat(currVal))).toString()
  }

  canCreateAndApprove: () => boolean = () =>
    LeaveHelpers.canApproveLeaveRequestForUser(this.state.currentUser, this.state.userId)

  onHeightAdjust: () => void = () => {
    if (this.form != null) {
      this.setState({ formHeight: `${this.form.offsetHeight}px` })
    }
  }

  setForm: (form: ?HTMLDivElement) => void = (form: ?HTMLDivElement) => {
    this.form = form

    if (form != null) {
      this.setState({ formHeight: `${form.offsetHeight}px` })
    }
  }

  submit: () => void = () => {
    const user = this.state.users.find((u) => u.id === this.state.userId)

    if (user == null) {
      return
    }

    const data = new FormData()
    data.append("self_approve", this.state.selfApprove.toString())
    data.append("leave_request[all_day]", this.state.allDay.toString())
    data.append("leave_request[finish]", this.state.endDate.format("YYYY-MM-DD"))
    data.append("leave_request[hours]", this.state.hours)
    data.append("leave_request[reason]", this.state.reason)
    data.append("leave_request[start]", this.state.startDate.format("YYYY-MM-DD"))
    data.append("leave_request[user_id]", user.id)
    data.append("leave_request[daily_breakdown]", JSON.stringify(this.state.daily_breakdown))

    if (this.state.departmentId != null) {
      data.append("leave_request[department_id]", this.state.departmentId)
    }
    if (this.state.leaveTypeName != null) {
      data.append("leave_request[leave_type]", this.state.leaveTypeName)
    }
    if (this.state.fallbackLeaveTypeName != null) {
      data.append("leave_request[fallback_leave_type]", this.state.fallbackLeaveTypeName)
    }
    if (this.state.verification != null) {
      data.append("leave_request[verification]", this.state.verification)
    }
    if (!this.state.allDay) {
      data.append("leave_request[start_time]", Modal.timeStringForAPICall(this.state.startTime))
      data.append("leave_request[finish_time]", Modal.timeStringForAPICall(this.state.endTime))
    }
    // Only specify fill-from-roster-strategy if the breakdown has been at least partially filled from roster
    if (
      this.state.daily_breakdown.some((shift_summary) =>
        ["roster", "unpublished_roster"].includes(shift_summary.filled_from)
      )
    ) {
      data.append("leave_request[fill_from_roster_strategy]", this.state.fillFromRosterStrategy)
    }

    this.setState({ submit: "in-progress" })

    Request.post(Routes.leave_index_path(), data)
      .then((res) => {
        // tells the main leave application that this is done
        // the app can then do something in response, eg. it can hide the modal if appropriate
        // Force fill from roster strategy into the response so that roster state can be updated
        this.props.onSuccess({ ...res.data, fill_from_roster_strategy: this.state.fillFromRosterStrategy })

        // update the modal state, this ensures we always have fresh leave balances for everyone
        this.downloadState().then(() => {
          this.setState({
            ...Modal.freshState(this.props.defaults, this.state),
            submit: "ready",
            submitError: null,
          })
        })
      })
      .catch((e) => {
        const submitError =
          e.response && e.response.data && e.response.data.text
            ? e.response.data.text
            : "Something went wrong, please try again."
        this.setState({ submit: "failure", submitError })
      })
  }

  render(): React.Node {
    return (
      <ModalView onExit={this.props.onCancel} open={this.props.open} size="m">
        <Header titleText={t("title")} />
        <SafeTransition
          className={styles.body}
          component="div"
          style={{ height: this.state.formHeight }}
          transitionAppear
          transitionAppearTimeout={150}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={150}
          transitionName={STATE_TRANSITION}
        >
          {(() => {
            if ([this.state.init, this.state.submit].includes("failure")) {
              return (
                <div className={styles.form} key="FAILURE_STATE">
                  <Failure
                    backAction={this.state.submitError ? this.resetSubmission : null}
                    setRef={this.setForm}
                    text={this.state.submitError}
                  />
                </div>
              )
            }

            if (this.state.init === "in-progress") {
              return (
                <div className={styles.form} key="LOADING_STATE">
                  <Loading setRef={this.setForm} />
                </div>
              )
            }

            return (
              <div className={styles.form} key="MODAL_FORM">
                <Form
                  allDay={this.state.allDay}
                  canSelfApprove={this.canCreateAndApprove()}
                  currentLeaveBalance={this.getCurrentLeaveBalanceHours(this.state.leaveTypeName)}
                  currentUser={this.state.currentUser}
                  daily_breakdown={this.state.daily_breakdown}
                  days={this.state.days}
                  defaultLeaveLength={this.state.defaultLeaveLength}
                  departmentId={this.state.departmentId}
                  endDate={this.state.endDate}
                  endTime={this.state.endTime}
                  fallbackLeaveTypeName={this.state.fallbackLeaveTypeName}
                  fetchingDailyBreakdown={this.state.fetchingDailyBreakdown}
                  fetchingLeaveAppliesOnDates={this.state.fetchingLeaveAppliesOnDates}
                  fillFromRosterStrategy={this.state.fillFromRosterStrategy}
                  finalLeaveBalance={this.getFinalLeaveBalance()}
                  forUser={this.props.defaults.userId != null}
                  hours={this.state.hours}
                  initialDailyBreakdown={this.state.initialDailyBreakdown}
                  isLeaveAveragingLeaveType={this.state.isLeaveAveragingLeaveType}
                  leaveAppliesOnDates={this.state.leaveAppliesOnDates}
                  leavesList={this.state.leavesList}
                  leaveTypeName={this.state.leaveTypeName}
                  onAllDayChange={this.handleAllDay}
                  onDepartmentIdChange={this.handleDepartmentIdChange}
                  onEndDateChange={this.handleEndDateChange}
                  onEndTimeChange={this.handleEndTimeChange}
                  onFallbackLeaveTypeChange={this.handleFallbackLeaveTypeChange}
                  onFillFromRosterStrategyChange={this.handleFillFromRosterStrategyChange}
                  onHoursChange={this.handleHoursChange}
                  onLeaveTypeChange={this.handleLeaveTypeChange}
                  onReasonChange={this.handleReasonChange}
                  onSaveDailyBreakdown={this.handleSaveDailyBreakdown}
                  onSelfApproveChange={this.handleSelfApproveChange}
                  onShiftOverlapChange={this.handleShiftOverlapChange}
                  onStartDateChange={this.handleStartDateChange}
                  onStartTimeChange={this.handleStartTimeChange}
                  onUserIdChange={this.handleUserIdChange}
                  onVerificationChange={this.handleVerificationChange}
                  pendingPptAcceptanceDates={this.state.pendingPptAcceptanceDates}
                  reason={this.state.reason}
                  selfApprove={this.state.selfApprove}
                  setRef={this.setForm}
                  shiftsList={this.state.shiftsList}
                  startDate={this.state.startDate}
                  startTime={this.state.startTime}
                  userId={this.state.userId}
                  users={this.state.users}
                  verification={this.state.verification}
                  verificationError={[this.fileFormatError(), this.fileSizeError()].join(" ")}
                  verificationTypes={this.state.verificationTypes}
                  viewInDays={this.state.viewInDays}
                />
              </div>
            )
          })()}
        </SafeTransition>
        <Footer>
          <FooterLeft>
            <Button disabled={this.isSyncing()} label={t("cancel_button")} onClick={this.props.onCancel} type="ghost" />
          </FooterLeft>
          <FooterRight>
            <Button
              disabled={
                this.invalidForm() ||
                this.invalidFile() ||
                this.isSyncing() ||
                this.state.submit === "failure" ||
                !!this.isWithOverlappingApprovedRequest() ||
                this.state.shiftOverlap
              }
              label={t("create_button")}
              loading={this.isSyncing()}
              onClick={this.submit}
              type="action"
            />
          </FooterRight>
        </Footer>
      </ModalView>
    )
  }
}

const STATE_TRANSITION = {
  enterActive: styles.enterActive,
  enter: styles.enter,
  appearActive: styles.appearActive,
  appear: styles.appear,
  leaveActive: styles.leaveActive,
  leave: styles.leave,
}
